<template>
  <el-config-provider :locale="locale">
    <!-- 这里是内容 -->
    <router-view></router-view>
  </el-config-provider>
</template>

<script setup>
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import { ref, onMounted, getCurrentInstance, onUnmounted } from "vue";
const { proxy } = getCurrentInstance();
const locale = zhCn;
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
onMounted(() => {
  // changeFavicon();
});
function changeFavicon() {
  let favicon = document.querySelector('link[rel="icon"]');
  let link = `./favicon.ico`;
  if (proxy.ClientId == proxy.AssociationClient) {
    link = proxy.BaseStyle.associationBase.icoName ? `./${proxy.BaseStyle.associationBase.icoName}.ico` : `faviconMz.ico`;
  }

  if (favicon !== null) {
    favicon.href = link;
  } else {
    favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.href = link;
    document.head.appendChild(favicon);
  }
}
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
if (window.location.href.indexOf("/login") == -1) {
  initWebSocket();
}

async function initWebSocket() {
  let token = JSON.parse(sessionStorage.getItem(proxy.ClientId + "Token")).access_token;
  proxy.$socket.connect(proxy.Services.MsgWss, token);
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --el-color-primary: #5078ed;
  --el-color-primary-light-3: #6e8deb;
  --el-color-primary-light-5: #89a3f0;
  --el-color-primary-light-7: #aabbf0;
  --el-color-primary-light-8: #c0ccf0;
  --el-color-primary-light-9: #eff3fe;

  --el-menu-bg-color: #5078ed;
  --el-menu-hover-bg-color: #3255b8;
}
.tox {
  z-index: 3998 !important;
}
.selectDate{
  z-index: 200 !important;
}
</style>
