import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import './assets/css/common.css'
// import './assets/css/element-variables.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import './assets/font/iconfont.css'
import * as echarts from 'echarts'
import centerMap from './components/common/centerMap.vue' //引入组件
import headCenter from './components/common/headCenter.vue' //引入组件
import bread from "./components/common/breadcrumb.vue";
import { TokenClient } from './components/TokenClient'
import { ApiClient } from './components/TokenClient/ApiClient'
import Vue3Tinymce from '@jsdawn/vue3-tinymce'
// 定义全局自定义过滤器
import filters from './utils/timeMth'
// import BaiduMap from 'vue-baidu-map-3x'
import { quillEditor } from 'vue-quill-editor/src'; //调用编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import websocket from "./utils/WebSocket";
app.config.globalProperties.$socket = websocket.Instance
app.component('head-center', headCenter)
app.component('center-map', centerMap)
app.component('bread', bread)
app.component('quillEditor', quillEditor)
// app.config.globalProperties.$http = axios;
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$filters = filters
app.config.globalProperties.showMsgDot = false

// axios.defaults.baseUrl = 'http://127.0.0.1:3333/'
initServer()
// 导航守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登录
router.beforeEach((to, from, next) => {
    if (to.path != '/login') {
        if (app.config.globalProperties.TokenClient.RefreshToken()) {
            next()
        }
        else {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            })
        }
    } else {
        window.sessionStorage.clear();
      window.localStorage.clear()
        next()
    }
})
app.use(store)
    .use(router)
    .use(ElementPlus)
    // .use(JsonExcel)
    .use(Vue3Tinymce)
    // .use(BaiduMap, {
    //     // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    //     ak: 'C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S'
    // })
    .mount("#app");
function initServer() {
    ApiClient.Get(
        '/services.json',
        false,
        [],
        null,
        function (res) {
            var data = JSON.parse(res)
            app.config.globalProperties.Services = {
                Authorization: data.Authorization,
                Notice: data.Notice,
                Healthy: data.Healthy,
                Shopping: data.Shopping,
                MsgWss: data.MsgWss
            }
            app.config.globalProperties.IdentityServer = data.IdentityServer;
            app.config.globalProperties.ClientId = data.ClientId;
            app.config.globalProperties.Secret = data.Secret;
            app.config.globalProperties.YunClient = data.YunClient
            app.config.globalProperties.FairClient = data.FairClient
            app.config.globalProperties.TotalClient = data.TotalClient
            app.config.globalProperties.AssociationClient = data.AssociationClient
            app.config.globalProperties.QYClient = data.QYClient
            app.config.globalProperties.CommonFilePath = `${data.Healthy}${data.CommonFilePath}`
            app.config.globalProperties.CommonPath = data.CommonPath;
            app.config.globalProperties.BaseStyle = data.BaseStyle;

            app.config.globalProperties.TokenClient = new TokenClient(
                app.config.globalProperties.IdentityServer,
                app.config.globalProperties.ClientId,
                app.config.globalProperties.Secret, '', '', ''
            )
        },
        function (ex) {
            console.log('加载配置文件错误！')
        }
    )
}
